@import "@Styles/_breakpoints.sass"

.btn
    &:hover, &:focus
        box-shadow: none

    &-override
        vertical-align: inherit
        border: 0
        line-height: inherit
        border-radius: 0
        font-weight: inherit
        text-align: inherit

    &-primary
        border: 0
        box-shadow: 0 0 0 2px #005ca9
        background: #005CA9
        color: #ffffff
        border-radius: 0

        &:hover, &:focus
            background: #ffffff
            color: #005CA9
            box-shadow: 0 0 0 2px #005CA9
            border-color: #005CA9

        &.disabled,
        &:disabled
            pointer-events: none
            background: #d9d9d9
            color: #6b7073
            border-color: #bdbdbd
            box-shadow: 0 0 0 2px #bdbdbd

        &:not(:disabled):not(.disabled):active
            background: #F4F4F4
            color: #373A3C

    &-secondary
        background-color: #FFFFFF
        box-shadow: 0 0 0 2px #005CA9
        border-radius: 0
        border: none
        color: #005CA9

        &:hover, &:focus
            background-color: #005CA9
            box-shadow: 0 0 0 2px #005CA9
            color: #FFFFFF
            text-decoration: none

        &:not(:disabled):not(.disabled):active
            background-color: #0E60CB
            color: #fff

        &[disabled],
        &:disabled,
        &.disabled
            color: #919598
            box-shadow: 0 0 0 2px #D8D8D8
            background: #EFEFEF
            pointer-events: none

    &-third
        background: #C9C9C9
        border-color: #C9C9C9
        color: #373A3C

        &:hover, &:focus
            background: #b7b7b7
            border-color: #b7b7b7
            color: #373A3C

    &-editQuota
        @extend .btn-secondary
        width: 28px
        height: 28px
        border-radius: 0px
        &:hover, &:focus
            border-color: #0E60CB

    &-quotaAccept
        @extend .btn-primary
        width: 28px
        height: 28px
        border-radius: 0px

    &-cancel
        background: #e6e6e6
        border-color: #C9C9C9
        color: #373A3C
        padding: 0 6px
        font-size: 1.4rem

    &-primary, &-secondary
        &.icon
            padding: 0
            width: 55px
            i
                font-size: 1.3rem

            &-xl
                i
                    font-size: 1.5rem
    &-editQuota, &-quotaAccept
        &.icon
            padding: 0
            width: 28px
            margin-right: 10px
            i
                font-size: 1.3rem
                display: flex
                align-items: center
                justify-content: center

    &-external
        display: block
        color: #373A3C
        background-color: #E6E6E6
        border-color: #D5D5D5
        width: 100%
        padding: 11px

        &:hover,
        &:focus
            color: #FFF
            background-color: #1A73E8
            border-color: #D5D5D5

        &:active
            color: #FFF
            background-color: #0E60CB
            border-color: #D5D5D5

        i
            font-size: 0.8rem
            line-height: 0.7rem
            vertical-align: text-top

    &-submit
        @extend .btn-primary
        padding: 10px
        min-width: 215px
        font-size: 1.062rem

    &-link
        @extend .btn-override

        background-color: transparent
        color: #373A3C
        padding: 0
        font-size: inherit
        text-decoration: underline
        &:hover
            text-decoration: underline

    &-link-external
        @extend .btn-link

        i
            font-size: 0.65rem
            line-height: 0.7rem
            vertical-align: text-top

    &-link-onblack
        @extend .btn-link

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-link-external-onblack
        @extend .btn-link-external

        color: #FFFFFF

        &:hover
            color: #CBCBCB

        &:active
            color: #329AFC

    &-icon
        @extend .btn-override
        padding: 0

    &-dropdown
        background: #FFF
        border-color: #DDDDDD
        color: #373A3C
        &:hover,
        &:focus,
        &:active
            background: #F4F4F4
            border-color: #DDDDDD
            color: #373A3C

    &-dropdown-secondary
        @extend .btn-dropdown
        width: 100%
        border-top: 4px solid #1C7CD5
        display: flex
        justify-content: space-between
        margin-top: 28px
        &::after
            display: none

    &-download
        &:hover,
        &:focus
            background: #1A73E8
            color: #FFF
        &:active
            background: #0E60CB
            color: #FFF

.show
    .btn-dropdown
        background: #F4F4F4
        border-color: #DDDDDD
        color: #373A3C

    .btn-viewer-button
        background: #1E2026
        color: #DDDDDD
        text-decoration: none
        box-shadow: none
