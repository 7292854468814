@import "@Styles/_breakpoints.sass"
@import "@Styles/_text.sass"
@import "@Styles/_font-colors.sass"

.layers
    color: #4A4A4A
    +fullhd
        margin-left: 248px
    &__title
        @extend .text--title
        margin-bottom: 33px
    &__nav-wrapper
        padding-top: 40px
        padding-bottom: 30px
        border-bottom: 1px solid #DDDDDD
    &__additional-text
        margin-top: 35px
        font-size: 0.9375rem
        font-weight: bold
    &__main
        padding-bottom: 30px
        +touch
            padding-bottom: 0px
    &__link
        padding: 7px 25px
        &--active
            @extend .layers__link
            box-shadow: inset 0px 4px 0 0px #1C7CD5
            background-color: #FCFCFC
    &__desktopNav
        +touch
            display: none
    &__mobileNav
        display: none
        +touch
            display: block
            text-align: right
        &--dropdown
            margin-bottom: 27px
        &--dropdown-menu
            width: 100%
            text-align: center
            a
                color: #1C7CD5
                padding: 5px
    &__project-info
        margin-top: 10px
        &--title
            font-weight: 700
            text-decoration: underline
            color: #161615
            &:hover
                color: #161615
        &--creator
            a
                text-decoration: underline
                color: #161615

    &__status
        margin-top: 50px
        margin-bottom: 60px
        & div
            margin-bottom: 8px

    &__form
        margin: 1rem 0

    &__table
        &--caption
            background-color: #0B8087
            color: #ffffff
            font-size: 1.25rem
            padding: 21px 50px
            width: 368px
        &--row
            display: grid
            grid-template-columns: 110px 300px 1fr 450px
            +touch-sec
                grid-template-columns: 60px 200px 1fr 350px
            +touch
                grid-template-columns: 50px 150px 1fr 320px
            +mobile
                grid-template-columns: 1fr
        &--row-one
            grid-template-columns: 110px 300px 1fr 280px
            +touch-sec
                grid-template-columns: 60px 200px 1fr 200px
            +touch
                grid-template-columns: 50px 150px 1fr 180px
            +mobile
                grid-template-columns: 1fr

        &--row__readOnly
            display: grid
            grid-template-columns: 110px 300px 1fr

        &--header
            margin-top: 3px
            margin-bottom: 6px
            background-color: #4A4A4A
            color: #ffffff
            padding: 15px
            +mobile
                display: none
        &--item
            padding: 0
            margin-bottom: 8px
            +mobile
                border: 1px solid #B6B6B6
            & div.buttons
                border: 1px solid #B6B6B6
                +mobile
                    border: none
                    height: 108px
            & div:not(.buttons)
                border: 1px solid #B6B6B6
                padding: 15px
                +mobile
                    border: none
                    padding: 10px 15px

            &-desc
                display: none
                +mobile
                    display: block
            &-name
                color: #005CA9

            & div.buttonOne
                grid-template-columns: 1fr
                +mobile
                    height: 54px

        &--col-button
            padding: 0
            display: grid
            grid-template-columns: 170px 1fr
            gap: 3px
            align-items: center
            +touch-sec
                grid-template-columns: 140px 1fr
            +touch
                grid-template-columns: 130px 1fr
            +mobile
                grid-template-columns: 1fr
            & button
                width: auto
                height: 100%
                border: none
                box-shadow: none
                +mobile
                    border: 1px solid #005CA9

    &__add
        &--container
            max-width: 570px
        &--wrapper
            flex: 1
            position: relative
        &--form
            display: flex

            &-submit
                min-width: 135px
                padding: 6px 45px
                font-size: 1rem
                border-top-left-radius: 0
                border-bottom-left-radius: 0
                align-self: flex-start
                height: 42px
                margin-left: -1px
                +touch
                    padding: 6px 30px
                    min-width: 100px

            &-input
                flex: 1
                height: 42px
                border-top-right-radius: 0
                border-bottom-right-radius: 0
                &::placeholder
                    font-size: 0.875rem
                label
                    display: none
                input
                    height: 42px
                    border-top-right-radius: 0
                    border-bottom-right-radius: 0
                    &::placeholder
                        font-size: 0.875rem

        &--dropdown
            font-size: 0.9375rem
            flex: 1

            &[aria-expanded="true"]
                border-bottom: none
            +mobile
                width: 100%
            &-btn
                background: #FFF
                border: transparent
                color: #1C7CD5
                width: 100%
                padding: 2px 4px
                text-align: left
                display: flex
                justify-content: space-between
                align-items: center
                border-bottom: 1px solid transparent
                text-transform: capitalize
                border-radius: 0

                &::after
                    color: #373A3C
                &:hover,
                &:focus
                    background: transparent
                    color: #1C7CD5
                    border-bottom-color: transparent
                &:focus
                    &::after
                        color: #373A3C
            &-role
                &:focus
                    &::after
                        color: #373A3C
                &[aria-expanded="true"]
                    &:focus
                        &::after
                            color: #FFF
                &[aria-expanded="false"]
                    &:hover
                        border-bottom: 1px solid #0E60CB
                        border-bottom-left-radius: 0px
                        border-bottom-right-radius: 0px
            &-list
                width: 200px
                margin-left: -51px
                margin-top: 8px
                +mobile
                    width: 100%
                    margin-left: 0
                &::before, &::after
                    content: ''
                    display: block
                    position: absolute
                    bottom: 100%
                    width: 0
                    height: 0

                &::before
                    left: 20px
                    border: 4px solid transparent
                    border-bottom-color: rgba(0, 0, 0, 0.15)

                &::after
                    left: 21px
                    border: 3px solid transparent
                    border-bottom-color: #fff

            &-item
                font-size: 0.9375rem
                line-height: 1.75rem
                color: #217ACC
                padding: 3px 35px
                text-transform: capitalize
                &--active
                    color: #373A3C
                    i
                        position: absolute
                        left: 5px

    &__dropdown
        font-size: 0.9375rem
        width: 235px

        &--list
            width: 235px
            margin-top: 8px
            margin-left: 0
            &-item
                text-transform: none

    &__group
        margin-bottom: 18px
        &-creator
            margin-right: 10px
        &--container
            margin-bottom: 115px
            padding-top: 59px
    &__dropdown-autocomplete
        width: 100%
        padding: 0.5rem 0
        border: 1px solid rgba(0, 0, 0, 0.15)
        border-radius: 0.25rem
        position: absolute
        &::before, &::after
            content: ''
            display: block
            position: absolute
            bottom: 100%
            width: 0
            height: 0

            &::before
                left: 20px
                border: 4px solid transparent
                border-bottom-color: rgba(0, 0, 0, 0.15)

            &::after
                left: 21px
                border: 3px solid transparent
                border-bottom-color: #fff
        &--item
            color: #217ACC
            padding-left: 19px
            padding-top: 5px
            &:hover
                background-color: #F2F2F2
                cursor: pointer
.permissions__dropdown--wrapper
    width: 240px
