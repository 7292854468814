@import '@Styles/_breakpoints.sass'
@import '@Styles/_auth.sass'

.account
    &__table
        &--row
            +tablet
                display: grid
                grid-template-columns: 160px auto
                align-items: baseline
            margin: 10px 0
            grid-gap: 10px

        &--value
            &-split
                display: flex
                flex-direction: column
                +tablet
                    flex-direction: row
                    justify-content: space-between
        &--additional-text
            color: #6D7274

    &__groups
        margin-top: 75px
        +desktop
            margin-top: 150px

        &--title
            +tablet
                display: flex
                justify-content: space-between

        &--admin, &--rest, &--blank
            margin-top: 25px

    &__adminlist
        margin: 20px 0 60px

        &--item
            margin: 10px 0
            border: 1px solid #D5D5D5
            border-radius: 5px
            display: grid
            grid-template-columns: 50px auto
            +tablet
                grid-template-columns: 50px auto 125px

            &-bg
                background: #ECEEEF
                &:not(:last-child)
                    border-right: 1px solid #D5D5D5

            & > div
                padding: 8px 10px

            &-lp
                @extend .account__adminlist--item-bg
                text-align: center
                color: #666666

            &-name
                @extend .account__adminlist--item-bg
                +mobile
                    border-right: 0 !important

            &-button
                text-align: center
                +mobile
                    grid-column: 1 / span 2
                    border-top: 1px solid #D5D5D5

    &__password-form
        margin-top: 70px

    &__list
        margin: 20px 0 0
        padding: 0
        list-style-type: none

        &--item
            margin: 10px 0
            display: flex

            &-lp
                margin-right: 5px
                +tablet
                    margin-right: 30px

            &-name
                margin-right: 3px
