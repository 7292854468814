@import "@Styles/_breakpoints.sass"
@import "@Styles/_text.sass"

.form
    label
        margin-top: 24px
        margin-bottom: 0px
        color: #4A4A4A

    &--info
        padding-top: 60px
        font-size: 0.875rem