@import "@Styles/_breakpoints.sass"
@import "@Styles/_font-colors.sass"
.myacount-dropdown
    &__button
        background-color: #FFFFFF
        font-size: 1.0625rem
        height: auto
        width: auto
        border: none
        border-radius: 0px
        padding: 2px 2px
        margin: 0px 10px
        line-height: 20px
        color: #005CA9
        border-bottom: 1.5px solid transparent
        &::after
            margin-left: 14px
        +touch
            padding-left: 0
            margin-left: 0
            color: #000000

        &:hover,
        &:focus
            border-color: #FFFFFF
            color: #005CA9
            background-color: #FFFFFF
            border-bottom: 1.5px solid #005CA9

        &[aria-expanded="true"]
            color: #005CA9
            background-color: #FFFFFF
            border-color: #1C4191
            &::after
                transform: rotate(180deg)

        +touch
            display: flex
            justify-content: space-between
            align-items: center
            width: 100%
            height: 38px
            font-size: 1rem
            background-color: #F8F8F8
            border-color: #D3D3D3
            &::after
                color: #404244

            &:hover
                background-color: #F8F8F8
                border-color: #D3D3D3
                color: #373A3C
                font-size: 1rem

            &[aria-expanded="true"]
                background-color: #F8F8F8
                border-color: #D3D3D3

        &:focus
            outline: none

    &__menu
        max-height: calc(100vh - 150px)
        overflow-y: auto
        margin-top: 5px
        left: auto
        right: 0
        min-width: 190px
        font-size: 0.875rem
        border: 1px solid #DDDDDD
        border-radius: 0px
        padding: 10px 0 0 0

        z-index: 1022

        &::before
            content: ''
            display: block
            position: absolute
            right: 12px
            bottom: 100%
            width: 0
            height: 0
            border: 4px solid transparent
            border-bottom-color: #DDDDDD

        &::after
            content: ''
            display: block
            position: absolute
            right: 13px
            bottom: 100%
            width: 0
            height: 0
            border: 3px solid transparent
            border-bottom-color: #FFF

        +touch
            background-color: #FFF
            border-color: #DDD

            &::before
                border-bottom-color: #DDD

            &::after
                border-bottom-color: #FFF

    &__item
        color: #005CA9
        font-size: 0.875rem
        text-decoration: none
        height: 40px
        display: flex
        align-items: center
        padding-top: 8px
        padding-bottom: 8px
        background-color: #FFFFFF

        &--header
            text-decoration: none
            font-size: 1.1rem
            font-weight: bold
            margin-top: 0.5rem
            margin-bottom: 0.5rem
            &:hover
                text-decoration: none
                background-color: #fff

        &--buttons
            justify-content: flex-end
            margin-bottom: 20px
            &:hover
                background-color: #fff
        &--logout
            justify-content: flex-end
            margin-bottom: 20px
            padding: 0.25rem 1.5rem
            display: flex
            align-items: center
            &:hover
                background-color: #fff
            &:active
                background-color: #fff

        +touch
            color: #383B3C

        &:hover
            background-color: #DFEAF4
            +touch
                background-color: #DFEAF4
                color: #1A73E8

        &:active
            background-color: #F4F4F4
            color: #373A3C

        &--not-active
            color: #1A73E8

        i
            position: absolute
            left: 13px
