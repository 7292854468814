@import "@Styles/_font-colors.sass"
.progress__bar
    &--wrapper
        height: 10px
        background-color: #FFF
        border: 1px solid #DDDDDD
        border-radius: 6px
        display: flex
        flex: 1

    &--meter
        background-color: #219AA2
        display: flex
        margin: 1px
        border-radius: 6px

    &--active
        display: flex
        margin: 1px
        border-radius: 6px
        background-color: #5CB95C
