
@mixin used-font
  font-family: 'Readex Pro', sans-serif

$extralight: 200
$light: 300
$regular: 400
$medium: 500
$semibold: 600
$bold: 700

h1
  font-size: 2.5rem
  font-weight: $semibold
  @include used-font

h2
  font-size: 1.75rem
  font-weight: $medium
  @include used-font

h3
  font-size: 1.25rem
  font-weight: $medium
  @include used-font

h4
  font-size: 1rem
  font-weight: $medium
  @include used-font

h5
  font-size: 0.875rem
  font-weight: $regular
  @include used-font

h6
  font-size: 0.75rem
  font-weight: $regular
  @include used-font