@import @Styles/_breakpoints
@import @Styles/_font-colors
.search-section
    margin-top: 0
    margin-bottom: 0
    padding: 33px
    background-color: #254B7D

    &__header
        font-size: 1.75rem
        text-align: center
        color: #ffffff
        margin-bottom: 30px

    &__additional-info
        color: #ffffff
        text-align: center
        font-size: 1rem

    &__wrapper
        display: flex
        background-color: #005CA9
        justify-content: center
        flex-direction: row
        width: 100%
        flex-wrap: wrap
        gap: 10px

        &--container
            display: grid
            grid-template-columns: 1fr auto
            gap: 10px
            position: relative
            padding-top: 33px
            padding-bottom: 33px
            +desktop
                width: 100%
            +touch
                width: 100%
                grid-template-columns: auto

            &-filter
                width: 23%
                height: 50px
                border: 1px solid grey
                border-radius: 3rem 0 0 3rem
                padding: 5px 23px
                font-size: 1rem
                display: flex
                align-items: center
                justify-content: center
                white-space: nowrap
                background-color: #FFF
                z-index: 10
                color: #161616
                cursor: pointer

                i
                    margin-left: auto

                +touch
                    font-size: 0.875rem
                +mobile
                    font-size: 0.75rem
                    white-space: break-spaces
                    text-align: center

            &-filters
                padding-top: 55px
                padding-left: 50px
                width: 23%
                position: absolute
                top: 0px
                z-index: 0
                border: 1px solid grey
                border-radius: 2rem 0 2rem 2rem
                background-color: #FFF
                +touch
                    font-size: 0.875rem
                    padding-left: 10px
                +mobile
                    font-size: 0.75rem
                    white-space: break-spaces
                    text-align: center
                    padding-left: 5px

                button
                    margin-top: 2px
                    border: 0
                    background-color: #fff
                    cursor: pointer
                    &:hover
                        text-decoration: underline

            &-form
                display: contents
                flex-wrap: wrap
                +touch-sec
                    display: flex
                    gap: 38px

            &-inner-left
                background-color: #fff
                display: grid
                grid-template-columns: 260px 1fr 218px
                +touch
                    display: flex
                    width: 100%
                    flex-direction: column
                    gap: 8px

                & :global(.dropdown-common)
                    padding: 8px
                    margin-top: auto
                    margin-bottom: auto

                & :global(.dropdown-common button)
                    border: none
                    border-radius: 0
                    border-right: 1px solid #c4c4c4
                    font-size: 1.125rem

                & :global(.dropdown-item)
                    font-size: 1rem

            &-inner-right
                display: flex
                gap: 10px
                +touch
                    width: 100%
                    flex-direction: column

                & :global(button)
                    border-width: 2px
                    height: 100%
                    +touch
                        width: 100%

            &-input-wrapper
                display: flex
                align-items: center
                +touch
                    margin-left: 20px

                & :global(i)
                    font-size: 1.25rem

            &-input
                height: 50px
                border: none
                padding: 10px
                margin-top: auto
                margin-bottom: auto
                margin-right: 8px
                font-size: 1.125rem
                width: 100%
                +mobile
                    min-width: 200px

                &::-webkit-input-placeholder
                    font-style: normal

            &-label
                display: none

            &-btn-clear
                background-color: #CCCCCC
                border: 1px solid grey
                border-right: 0
                max-width: 50px
                padding: 5px 20px
                font-size: 1rem
                display: flex
                align-items: center
                justify-content: center
                +touch
                    font-size: 0.875rem
                +mobile
                    font-size: 0.75rem
            &-submit,
            &-submit:disabled
                background-color: white
                box-shadow: 0 0 0 2px #005CA9
                min-height: 68px
                border: 0
                font-size: 1.125rem
                &:hover
                    box-shadow: inset 0 0 0 2px white

            &-submit:disabled
                color: #626262

        &--button
            border: 1px solid #ffffff
            border-radius: 0
            min-height: 68px
            font-size: 1.125rem
            background-color: #005CA9
